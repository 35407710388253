import Login from "./pages/Login";
import Register from "./pages/Register";
import Recover from "./pages/Recover";
import { Routes, Route } from "react-router-dom";
import { PopupComp } from "./misc/popup";

function App() {
  return (
    <div className="App">
      <PopupComp>
        <Routes>
          <Route path="/" element={<Login />}/>
          <Route path="/register" element={<Register />}/>
          <Route path="/recover" element={<Recover />}/>
        </Routes>
      </PopupComp>
    </div>
  );
}

export default App;
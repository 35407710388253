import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import  secureLocalStorage  from  "react-secure-storage";

import Layout from "../components/Layout";
import { GlobalContext } from "../context/globalContext";
import { generateGtagEvent } from '../misc/analytics'
import { login as callLogin } from '../misc/endpoint'
import { StdValidations, useForm } from "../misc/formHook";
import { Popup } from '../misc/popup'

import { 
  Container,
  Box,
  InnerBox,
  Title,
  Label,
  Input,
  Button,
  LogoLobbyze,
  LogoSlotPoker,
  FieldsetEmail,
  FieldsetPass,
  ErrorSpan,
  VerticalExpander,
  EyePassIcon,
  Span
} from "../styles/globalStyles";
import {
  Body,
  Divider,
  DividerText,
  BoxRegisterEmail,
  BoxRegisterFacebook,
  BoxRegisterGoogle,
  Esqueceu,
  PrivacyPolicy
} from "../styles/loginStyles";

import lobbyzeLogo from "../assets/logo_lobbyze.svg"
import slotpokerLogo from "../assets/logo-slotpoker.png"

const Login = () => {

  const { hintEmail, setPopup, serverMsg } = useContext(GlobalContext)

  const { t } = useTranslation()

  const [ showUnauthorized, setShowUnauthorized ] = useState(false)

  const [ showPassword, setShowPassword ] = useState(false)

  const { values, errors, submit, createField } = useForm(async () => {
    document.activeElement.blur()
    setPopup(Popup.LOADING)
    const [ status, finish ] = await callLogin(values.login, values.password)
    if(status === 200) {
      setPopup(Popup.LOGGED_IN)
      if(values.remember) {
        secureLocalStorage.setItem("storedUser", {
          email:  values.login,
          pass: values.password
        });
      } else {
        secureLocalStorage.removeItem("storedUser")
      }
      await generateGtagEvent('login', { method: 'email' })
      finish()
    } else if(status === 401) {
      setPopup(Popup.NONE)
      setShowUnauthorized(true)
    } else if(status == 403) {
      finish().then(e => {
        Popup.INFO.msg = e
        setPopup(Popup.INFO)
      })
    } else {
      finish().then(e => {
        Popup.ERROR.msg = e
        setPopup(Popup.ERROR)
      })
    }
  }, {
    login: hintEmail || '',
  })

  async function onThirdPartyLogin(method) {
    setPopup(Popup.LOADING)
    await generateGtagEvent('login', { method })
    window.location.href = `/auth/oauth/${method}`
  }

  return (
    <Layout>
      <Body>
        <Container>
          <Box>
            <InnerBox>
                <div style={{margin: "0 auto", width: "175px", display: "block"}}>
                  <LogoLobbyze src={lobbyzeLogo} />
                  <LogoSlotPoker src={slotpokerLogo} />
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                  <span style={{
                    display: (serverMsg ? 'block' : 'none'),
                    marginTop: '1rem',
                    color: '#c53030'
                  }}>{ serverMsg && t(serverMsg) }</span>
                  <span style={{
                    display: (showUnauthorized ? 'block' : 'none'),
                    marginTop: '1rem',
                    color: '#c53030'
                  }}>{t('msg.unauthorized')}</span>
                </div>
                <Title>{t('signin.title')}</Title>
                <FieldsetEmail>
                  <ErrorSpan>{ errors.login }</ErrorSpan>
                  <Input
                    type="text"
                    required id="email"
                    placeholder={t('signin.user&email.placeholder')}
                    {...createField('login', 'login', StdValidations.length(4, 120))} />
                  <Label htmlFor="email">{t('signin.user&email.label')}</Label>
                </FieldsetEmail>
                <FieldsetPass>
                  <EyePassIcon pressed={showPassword} onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)} />
                  <Esqueceu to={"/recover"}>{t('forgot')}</Esqueceu>
                  <ErrorSpan>{ errors.password }</ErrorSpan>
                  <Input 
                    type={showPassword ? 'text' : 'password'}
                    required id="password" 
                    placeholder={t('signin.pass.placeholder')}
                    {...createField('password', 'password', StdValidations.length(6))} />
                  <Label htmlFor="password">{t('signin.pass.label')}</Label>
                </FieldsetPass>
                <div>
                  <input
                    type='checkbox'
                    id="remember"
                    checked={false}
                    style={{ width: "auto" }}
                    {...createField('remember', 'remember', () => {return false})} />
                  <Span>{t('signin.remember')}</Span>
                </div>
                <VerticalExpander />
                <Button type="submit" onClick={() => submit()}>{t('login')}</Button>
                <Divider><DividerText>{t('signupText')}</DividerText></Divider>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <BoxRegisterEmail to={"/register"}/>
                  {/* <BoxRegisterFacebook href="#" onClick={onThirdPartyLogin.bind(null, 'facebook')} /> */}
                  <BoxRegisterGoogle href="#" onClick={onThirdPartyLogin.bind(null, 'google')} />
                </div>
            </InnerBox>
          </Box>
        </Container>
      </Body>
      <PrivacyPolicy target="_blank" href="https://lobbyze.com/privacy_policy.html">{t('Privacy')}</PrivacyPolicy>
    </Layout>
  );
};

export default Login;